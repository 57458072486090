import React from 'react'
import './Footer.css'


const Footer =( )=>{
    return(
        <div className='wrapper'>
                <h6 style={{margin:"auto"}}> Interaudio™ 2023</h6>
        </div>
    )
}

export default Footer;