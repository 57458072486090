import React from 'react'


import './CustomJumboTron.css'

const CustomJumboTron = () =>{
    return(
        <div className='jumbotron_wrapper'>                   
                         <p className='title'> Sonorización profesional para eventos sociales y musicales </p>
											 
                       <p className='title_two'> Audio - Iluminación - Djs - Pantallas Gigantes - Pista de baile iluminada - Fiestas - Bodas - Presentaciones - Eventos corporativos - Karaoke - Refuerzo sonoro a bandas y músicos en gral. <br/>
                          Spoken english.   
                       </p>

                        <p>
                        <a style={{marginTop:'10px'}} href="mailto:contacto@interaudio.mx" class="btn btn-primary" url="">Contacto </a>                                                                               
                        </p>
                        
        </div>
    )
}
export default CustomJumboTron;

